<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title class="elevation-1">
            Sub-Category({{ user.name }})
           <span class="pl-3"> <v-btn
              @click="editDialog = true"
              color="info"
              depressed
              small
              :elevation="0"
              >Update</v-btn
            ></span>
            <v-spacer></v-spacer>
          </v-card-title>
          <div class="pt-4">
            <v-row>
              <v-col cols="12" md="12">
                <v-row>
                  <v-col cols="12" v-if="category.length > 0">
                    <span class="item_title">Category</span>
                    <hr />
                    <br />
                    <span v-for="(cat, a) in categoryArray" :key="a">
                      <v-chip class="item_value ma-2">{{
                        cat.name
                      }}</v-chip></span
                    >
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Sub-Category</span> <hr /><br>
                    <v-chip class="item_value">{{ user.name }}</v-chip>

                  </v-col>
                </v-row>
               
              </v-col> -->
              <v-col cols="12" md="12" v-if="stylCategoryArray.length > 0">
                <v-row>
                  <v-col cols="12">
                    <span class="item_title">StylCategory</span>
                    <hr />
                    <br />
                    <span v-for="(stylCat, b) in stylCategoryArray" :key="b">
                      <v-chip class="item_value ma-2">{{
                        stylCat.name
                      }}</v-chip></span
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="12" v-if="sizeArray.length > 0">
                <v-row>
                  <v-col cols="12">
                    <span class="item_title">Size</span>
                    <hr />
                    <br />
                    <span v-for="(s, b) in sizeArray" :key="b">
                      <v-chip class="item_value ma-2">{{ s }}</v-chip></span
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="12">
                <v-row>
                  <v-col cols="12">
                    <span class="item_title">GST Details</span>
                    <hr />
                    <br />
                    <v-row>
                      <v-col cols="6" md="4">
                        <span class="item_value"
                          >GST(slab1 percentage) :
                          {{ user.gstPercentage }}</span
                        >
                      </v-col>
                      <v-col cols="6" md="4">
                        <span class="item_value"
                          >GST(slab2 percentage) :
                          {{ user.gstLevel2Percentage }}</span
                        >
                      </v-col>
                      <v-col cols="6" md="4">
                        <span class="item_value"
                          >GST(slab2 minimum price ) :
                          {{ user.gstLevel2MinumumPrice }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="user.sizeChart" cols="12" class="pt-6">
                <v-row>
                  <v-col cols="12">
                    <v-row class="pb-4 pl-3">
                      <span class="item_title">
                        Size Chart Image
                        <v-btn
                          small
                          color="red"
                          depressed
                          :ripple="false"
                          @click="sizeChartDialog = true"
                          ><span style="color: #fff">Remove</span></v-btn
                        >
                      </span>
                    </v-row>
                    <hr />
                    <br />
                  </v-col>
                  <v-col  cols="6" class="text-center pt-3">
                    <v-img
                      height="auto"
                      width="100%"
                      :src="baseURL + user.sizeChart"
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="sizeChartDialog" max-width="600px">
      <v-card>
        <v-card-title
          >Are you sure you want to remove this size chart</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="sizeChartDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="removeSizeChart()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Subcategory</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="user.name"
                  label="Sub-Category"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  dense
                  multiple
                  v-model="user.category"
                  class="mt-5"
                  label="Category"
                  :items="categoryArray"
                  item-text="name"
                  item-value="_id"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="D6" md="12">
                <v-select
                  dense
                  multiple
                  v-model="user.styl"
                  class="mt-5"
                  label="Styl-Category"
                  :items="stylCategoryArray"
                  item-text="name"
                  item-value="_id"
                  :rules="[rules.required]"
                  required
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="user.gstPercentage"
                  label="GST (slab1 in percentage)"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model="user.gstLevel2Percentage"
                  label="GST (slab2 in percentage)"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model="user.gstLevel2MinumumPrice"
                  label="GST (slab2 minimum price)"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  dense
                  id="text"
                  v-model="size"
                  class="mt-5"
                  label="Size"
                  :rules="[rules.required]"
                  required
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="3" md="3" class="mt-5">
                <v-btn @click="editsize()"> Add Size </v-btn></v-col
              >
            </v-row>

            <v-row>
              <span v-for="(s, b) in user.size" :key="b">
                <v-chip class="ma-2" close @click:close="eddel(b)">{{
                  s
                }}</v-chip></span
              >
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p>Please Upload Size Chart</p>
                <ImageComp
                  :singleImage="user.sizeChart"
                  :pageId="user._id"
                  @stepper="winStepper"
                  :heading="'Upload size Chart'"
                  :componentType="'sizeChart'"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editDialog = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="edit()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  props: ["subid"],
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      search: "",
      currentPage: 1,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      itemid: "",
      category: [],
      sizeArray: [],
      categoryArray: [],
      stylCategory: [],
      stylCategoryArray: [],
      categoryView: "",
      itemediting: [],
      user: [],
      data: [],
      name: [],
      sizeChartDialog: false,
      sizeChart: null,
      editDialog: false,
      size: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  mounted() {
    this.getData();
    this.getCategory();
    this.getStylCategory();
  },
  watch: {
    categoryView() {},
    currentpage() {
      this.getData();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "sizeChart") {
        this.sizeChart = window_data.selectedFiles;
      }
    },
    initialize() {
      this.user = [];
    },
    editsize() {
      document.getElementById("text").value = "";
      this.user.size.push(this.size);
    },
    eddel(b) {
      // this.a=this.itemediting.edsize
      this.user.size.splice(b);
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/subcategory/get",

        method: "GET",
        params: {
          id: this.subid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.name = response.data.data.name;
          this.categoryArray = response.data.data.category;
          this.stylCategoryArray = response.data.data.styl;
          this.sizeArray = response.data.data.size;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCategory() {
      this.appLoading = true;
      axios({
        url: "/category/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.categoryArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getStylCategory() {
      this.appLoading = true;
      axios({
        url: "/styl/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.stylCategoryArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.dialog = false;
    },
    itemedit(item) {
      this.itemediting = item;
      this.dialog = true;
    },
    edit() {
      // this.editid=item;
      var data = {};
      data["id"] = this.subid;
      data["name"] = this.user.name;
      data["category"] = this.user.category;
      data["styl"] = this.styl;
      data["size"] = this.user.size;
      data["gstPercentage"] = this.user.gstPercentage;
      data["gstLevel2Percentage"] = this.user.gstLevel2Percentage;
      data["gstLevel2MinumumPrice"] = this.user.gstLevel2MinumumPrice;
      axios({
        url: "/subcategory/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.dialog = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.editDialog = false;
            this.getData();
             this.uploadImage(response.data.id);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeSizeChart() {
      axios({
        url: "/subcategory/RemoveSizeChart",
        method: "POST",
        data: {
          id: this.subid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.sizeChartDialog = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    
     uploadImage() {
      let formData = new FormData();
      formData.append("id", this.subid);
      formData.append("image", this.sizeChart);
      axios({
        method: "POST",
        url: "/subcategory/UploadSizeChart",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
            this.sizeChart = null;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
}
</style>
